import { cpexLog, cpexWarn, cpexError, clone, isFilledArray, regexGetValue, regexGetFirstComment, isPrebidLoaded, getRandomInt, loadScript, displayMetaData, isAdsObject, awaitDOM } from '../utils.js'

/**
 * This adapter replaces AdServer object instance registered in the main package instance
 */
export default class AdServerSasTracker {
  constructor (main) {
    this.adapter = 'sastracker'
    this.main = main
    this.dependenciesLoading = false
    this.areaKey = main.settings.adserver.areaKey || 'area'
    this.debugRenderAttempts = 0

    window.top.sasTracker = window.top.sasTracker || {}
    window.top.sasTracker.que = window.top.sasTracker.que || []
  }

  /**
   * Initializes an adserver based on settings. It's missing loading of sas-tracker script, but those are specific to each publisher and they load them on their side anyway
   */
  load () {
    cpexLog('AdServer: SAStracker adapter loading')
    this.domLoaded = awaitDOM()

    // TEMPORARY: Until Optimics publishes a fix in sas-tracker
    if (this.main.settings.publisher.code === 'vlm') {
      window.addEventListener('cpexAdRendered', e => { if (e.detail?.element && e.detail.element.dataset) { e.detail.element.dataset.loaded = true } })
    }

    if (this.main.settings.adserver.loadPrerequisites && this.dependenciesLoading !== true) {
      this.dependenciesLoading = true
      if (window.postscribe) {
        cpexWarn('Adserver: Postscribe already present')
        this.postscribeLoaded = Promise.resolve()
      } else {
        this.postscribeLoaded = loadScript('https://cdn.cpex.cz/package/prerequisites/postscribe.min.js', 'Postscribe')
          .then(() => { this.dependenciesLoading = false })
      }
      this.loading = Promise.all([this.domLoaded, this.postscribeLoaded]).catch(e => cpexError('Loading requirements failed', e))
    } else {
      this.loading = this.domLoaded
    }
    this.loading.then(() => { cpexLog('AdServer: SAStracker adapter loaded') })
    return this.loading
  }

  /**
   * Mandatory. Returns (as a promise) an array of elementIds for the page, to be used for headerbidding adUnits
   * Has to be a promise to act the same way as GAM.
   */
  async getAdsList () {
    const adsList = []
    for (const key in window.AdsObject) {
      if (key === 'ball') { continue }
      adsList.push(window.AdsObject[key][this.areaKey]) // if a publisher uses different positionIdentifyingParam we have to get this from settings
    }
    return adsList
  }

  /**
   * Mandatory. Calls the adserver to get the final ads selected and rendered
   * adUnits - optional array of adUnit names
   * NOTE: Needs window.AdsObject (list of elementIds/adUnits) to be present in the page
   */
  async call (adUnits) {
    // Wait for DOM ready
    await this.loading
    // Delay feature
    if (this.main.settings.adserver.delayCall) {
      cpexLog('AdServer: Adserver call delayed by ' + this.main.settings.adserver.delayCall)
      await new Promise(resolve => setTimeout(resolve, this.main.settings.adserver.delayCall)).catch(e => cpexError('Delay timeout failed', e))
    }
    // AdsObject check and filtering (used for partial refresh)
    if (!isAdsObject(window.AdsObject)) {
      return cpexError('AdServer: Missing window.AdsObject or ball property on it, call will fail')
    }
    let adsObjectBackup
    if (isFilledArray(adUnits)) {
      adsObjectBackup = clone(window.AdsObject)
      window.AdsObject = Object.fromEntries(Object.entries(window.AdsObject).filter(([key, value]) => adUnits.includes(value[this.areaKey])))
      window.AdsObject.ball = adsObjectBackup.ball
    }

    // Add consent to AdsObject
    if (this.main.settings.adserver.addConsent) { // Temporary, until Optimics implements it in all their sasTracker scripts. Used only for TN
      await this.addConsent()
    }
    // Enrich the AdsObject with bids from headerbidding
    if (this.main.headerbidding && isPrebidLoaded()) {
      this.addBids()
      window.dispatchEvent(new window.Event('cpexBidsAdded'))
    }
    // Specific to IINFO, they need to trigger their callback for sas response
    if (window.iinfo) {
      window.iinfo.adverts.sas.sas.onCpexSasTrackerTrack()
    } else if (this.main.settings.publisher.code !== 'echo') { // Skip for ECHO, they will call adserver themselves
      // Regular SAS call, or que in case it's still loading
      if (typeof window.sasTracker.track === 'function') {
        window.sasTracker.track()
      } else {
        window.sasTracker.que.push(() => {
          window.sasTracker.track()
        })
      }
      cpexLog('AdServer: SAS called with this AdsObject: ', window.AdsObject)
    }
    // Restore original AdsObject
    if (adsObjectBackup) {
      window.AdsObject = adsObjectBackup
    }
  }

  /**
   * Mandatory. Returns (in promise) DOM element id for the adUnit/hbKey
   */
  async getElementId (hbKey) {
    const adsList = await this.getAdsList()
    return adsList.includes(hbKey) ? hbKey : null
  }

  /**
   * Triggered from the render, it waits a moment for the ad to be rendered, then draws debug tags over it
   */
  adRenderDebug (domId, metaData) {
    if (this.main.debugMode) {
      // Wait for the ad to be rendered and registered in sastracker
      if (window.top.sasTracker.rr && window.top.sasTracker.rr[domId] && document.getElementById(domId)) {
        const sasAd = window.top.sasTracker.rr[domId]
        cpexLog('AdServer: SAS rendered this ad:', sasAd)
        if (sasAd.res[0] === '{') { metaData.json = true }
        const newId = document.getElementById(domId).getAttribute('data-target-id-moved')
        if (document.getElementById(newId)) {
          metaData.originalId = domId
          domId = newId
        }
        this.prepareMetaData(domId, metaData, sasAd.res)
      } else if (this.debugRenderAttempts <= 5) {
        this.debugRenderAttempts++
        setTimeout(() => { this.adRenderDebug(domId, metaData) }, 250) // try again later
      }
    }
  }

  /****************************************************************************/
  /* SPECIFIC methods, to this adapter only                                   */
  /****************************************************************************/

  /**
   * Refresh specific to sastracker, able to refresh only certain ad positions.
   * adUnits - optional array of adUnit codes
   */
  async refresh (adUnits) {
    // Check AdsObject
    if (!isAdsObject(window.AdsObject)) {
      cpexError('AdServer: Missing window.AdsObject or ball property on it, call will fail')
      return false
    }
    this.main.clearAds(adUnits) // only works if adUnits are elementIds
    // Enrich the AdsObject with bids from headerbidding
    if (this.main.headerbidding) {
      await this.main.headerbidding.refresh(adUnits)
    }
    // SAS call with optional subset of adUnits
    this.call(adUnits)
    cpexLog('AdServer: SAS refreshed with this AdsObject: ', window.AdsObject)
    return true
  }

  /**
   * Determines if the creative is from header-bidding
   */
  isFromHB (creative) {
    const firstComment = regexGetFirstComment(creative)
    return firstComment?.includes('HB')
  }

  /**
   * This method is an interface for the sas-tracker library from Optimics.
   * False tells them to render themselves, true means we render the creative, using our main render function.
   */
  render (elementId, creative, width, height) {
    cpexLog(`AdServer: SAStracker returned creative for ${elementId}, size: ${width}x${height}`)
    width = parseInt(width)
    height = parseInt(height)
    const fromHB = this.isFromHB(creative)
    const sspId = parseInt(regexGetValue(creative, 'SASF_ADVERTISERID'))
    try {
      setTimeout(() => { this.adRenderDebug(elementId, { width, height, fromHB, sspId }) }, 250)
    } catch (e) { console.error('Debug tags failed to render', e) }
    // Filter to only our advertisers, based on allowedSSPs from settings
    const external = !Object.values(this.main.settings.adserver.allowedSSPs).map(id => parseInt(id)).includes(sspId)
    if (fromHB || external) { // from HB or not our advertiser network
      // Let sas-tracker render HB ads, custom format catching and DSA will be called from within the HB creative
      this.main.regularAds[elementId] = { element: document.getElementById(elementId) }
      return false
    } else {
      // Catching for s2s creatives is part of the main render function
      // Returns false when not caught as a custom format
      return this.main.render(elementId, creative, width, height)
    }
  }

  /**
   * For testing: This method is an interface for sas-tracker library from Optimics, for JSON creatives from SAS.
   * False tells them to render themselves, true means we render the creative.
  renderJson (elementId, response) {
    const parsed = JSON.parse(response)
    const ad = parsed?.seatbid[0]?.bid[0]
    console.log('parsed creative', ad)
    cpexLog(`AdServer: SAStracker returned creative for ${elementId}, size: ${ad.w}x${ad.h}`)
    return this.main.renderAny(elementId, ad.adm, ad.w, ad.h)
  }
  */

  /**
   * Add winning bids to AdsObject, to be sent to the ad server
   */
  addBids () {
    for (const key in window.AdsObject) {
      if (key === 'ball') { continue }
      const entity = window.AdsObject[key]
      const winningBid = window.pbjs.getHighestCpmBids(entity[this.areaKey])[0]
      if (winningBid) {
        entity.hbid = winningBid.cpm
        entity.bidTier = winningBid.adserverTargeting.hb_pb
        entity.hbid_v = this.main.settings.adserver.bidderTable[winningBid.bidderCode] || 'unknown'
        entity.bidderCode = winningBid.bidderCode
        if (winningBid.size) { entity.bidderSize = winningBid.size }
        if (winningBid.dealId) { entity.bidDealId = winningBid.dealId }
        if (winningBid.vastUrl) { entity.hbVastUrl = encodeURIComponent(winningBid.vastUrl) }
      } else {
        // If nothing wins clear previous, in case this is used for refresh
        ['hbid', 'bidTier', 'hbid_v', 'bidderCode', 'bidderSize', 'bidDealId'].forEach(key => delete entity[key])
      }
    }
    // Add userIDs (once)
    const userIds = window.pbjs.getUserIds()
    if (userIds.pubcid) { window.AdsObject.ball.pcid = userIds.pubcid }
    if (userIds.id5id) { window.AdsObject.ball.id5 = userIds.id5id.uid }
    // Maybe later: Add pixels for userSync (SAS StateVector)
    // if (userIds.id5id && window.sasTracker.settings.baseServerUrl) { addPixel(window.sasTracker.settings.baseServerUrl + '/cent/SETSV/TTL=33696000/id5=' + userIds.id5id) }
    // Add AB test key
    if (this.main.ab.group && this.main.ab.sasKey) {
      window.AdsObject.ball[this.main.ab.sasKey] = this.main.ab.group
    }
  }

  /**
   * Get GDPR consent string from CMP and add it to the AdsObject
   */
  addConsent () {
    return new Promise((resolve, reject) => {
      try {
        window.__tcfapi('addEventListener', 2, (tcData, success) => {
          if (success && tcData && tcData.tcString) {
            window.AdsObject.ball.gdpr = '1'
            window.AdsObject.ball.consent = tcData.tcString
            resolve()
          } else { // don't throw error for 3rd party code
            reject() // eslint-disable-line
          }
        })
      } catch (e) {
        cpexError('AdServer: CMP not responding properly', e)
        reject(e)
      }
    })
  }

  /**
   * Use in case when ids are dynamically moved in the DOM, to update the references
   */
  updateReferences (elementIds) {
    elementIds.forEach(code => {
      if (this.main.regularAds[code]) { this.main.regularAds[code].element = document.getElementById(code) }
      if (this.main.customAds[code]) { this.main.customAds[code].element = document.getElementById(code) }
    })
  }

  /**
   * Prepares an object with useful information for debubbing. Merges info from both adserver and prebid.
   */
  prepareMetaData (elementId, metaData, creative) {
    const creativeMetaData = { // adserver data
      adapter: 'sasTracker ' + window.sasTracker.version?.substring(0, 3),
      id: (metaData.originalId || elementId) + ', fcid: ' + regexGetValue(creative, 'SASF_FCID'),
      size: metaData.width + 'x' + metaData.height,
      sspId: metaData.sspId
    }
    if (metaData.json) { creativeMetaData.json = true }
    if (this.main.customAds[metaData.originalId || elementId]) { // custom format
      creativeMetaData.customType = this.main.customAds[metaData.originalId || elementId].type
    }
    displayMetaData(elementId, creativeMetaData)
  }

  async requestPosition (adUnit) {
    const url = `${window.sasTracker.settings.baseServerUrl}/hserver/ball/random=${getRandomInt(10000000, 99999999)}/${window.sasTracker.settings.positionIdentifyingParam}=${adUnit}`

    const response = await fetch(url, { method: 'GET', mode: 'cors' }).catch(e => cpexError('AdServer: SAStracker failed to get position', e))
    if (response.status === 200) {
      const res = await response.text()
      console.log('SAS response', res)
    }
  }
}
